import styled, { css } from 'styled-components';
import { below } from '../../styles/libs';
import Text from '../ui/Text';

export const Bar = styled.div`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: ${theme.COLOR_AMBER_ORANGE};
    color: ${theme.COLOR_WHITE};
  `}

  ${({ theme }) => below(theme.TABLET)`
    display: block;
  `}
`;

export const Exit = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 1.5rem;
  color: ${({ theme }) => theme.COLOR_WHITE};
  cursor: pointer;

  ${({ theme }) => below(theme.TABLET)`
    position: static;
  `}
`;

export const ExitLabel = styled(Text)`
  margin-right: 0.5rem;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.TEXT_HEADING_FONT};
  font-size: 1.125rem;
`;

export const BarLabel = styled.span`
  text-align: right;
  font-family: ${({ theme }) => theme.TEXT_HEADING_FONT};
  font-size: 1.125rem;

  ${({ theme }) => below(theme.TABLET)`
    display: block;
    text-align: center;
    margin-top: 1rem;
  `}
`;

export default { Bar };
