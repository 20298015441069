import {
  VEHICLE_TYPES, CROSSINGS_VEHICLE_TYPES, CROSSINGS_TOW_TYPES,
  OVERSEAS_VEHICLE_TYPES, OVERSEAS_TOW_TYPES, TOW_TYPES, OVERSEAS_CAR_TOW_TYPES,
} from '../../config/outfits';
import PLACE_EVENT_TYPES from '../../config/eventTypes';
import PRODUCT_TYPES from '../../config/products';

export const shouldValidateOutfitLength = (
  outfitItem,
  isVehicle,
  isOverseas,
  config,
  eventType,
) => {
  if (!config) return true;
  const { towTypes, vehicleTypes } = config;
  const typeConfig = isVehicle
    ? vehicleTypes.find((vehicle) => vehicle.key === outfitItem)
    : towTypes.find((tow) => tow.key === outfitItem);
  // Check if we are validating this product code and event type combination
  const validatedProductCodes = typeConfig?.setDefaultVehicleLengthProductCodes;
  const validatedEventTypes = typeConfig?.setDefaultVehicleLengthEventTypes;

  if (validatedProductCodes?.length && validatedEventTypes?.length) {
    const shouldValidatePC = validatedProductCodes.includes(
      isOverseas ? PRODUCT_TYPES.CAMC_MEMBER_PRODUCT : PRODUCT_TYPES.CAMC_UK_PRODUCT,
    );
    const shouldValidateET = validatedEventTypes.includes(parseInt(eventType, 10));

    // if UK, and need to validate product code and event type,
    // we don't need to display/validate the length as the user would not have entered it
    if (!isOverseas && shouldValidatePC && shouldValidateET) return false;
  }
  // Special case for camping
  if (eventType === PLACE_EVENT_TYPES.CAMPING.id) {
    const isRooftent = isVehicle && outfitItem === VEHICLE_TYPES.ROOF_TENT;
    if (isRooftent) return true;
    return false;
  }
  return !typeConfig?.omitPitchLengthValidation || isOverseas;
};

export const getEventType = (quote) => {
  const { campsiteBookings, crossingBookings } = quote;
  const sitesEventType = campsiteBookings.map((site) => site.pitches?.[0]?.eventType);
  const crossingEventType = crossingBookings.map((site) => site.pitches?.[0]?.eventType);
  return sitesEventType?.[0] || crossingEventType?.[0];
};

export const stringifyVehicleType = (vehicleType, vehicleTypes = [], defaultValue = 'None') => {
  const result = vehicleTypes.find(
    (vehicleItem) => vehicleItem.key === Number(vehicleType ?? VEHICLE_TYPES.NONE),
  )?.value ?? defaultValue;
  if (result === 'None') {
    return defaultValue;
  }
  return result;
};

export const isNoneNullOrUndefined = (value, isTow) => (
  value === null ||
  value === undefined ||
  value === (isTow ? TOW_TYPES.NONE : VEHICLE_TYPES.NONE)
);

const filterOutfitOptions = (options, productCode, eventType, use, vehicleType, vehicleTypes) => {
  if (use === 'Crossings') {
    if (!vehicleTypes) {
      return options.filter((option) => CROSSINGS_VEHICLE_TYPES.includes(option.key));
    }
    return options.filter((option) => CROSSINGS_TOW_TYPES.includes(option.key));
  }
  if (productCode === PRODUCT_TYPES.CAMC_MEMBER_PRODUCT) {
    if (!vehicleTypes) {
      return options.filter((option) => OVERSEAS_VEHICLE_TYPES.includes(option.key));
    }
    if (vehicleType === VEHICLE_TYPES.CAR) {
      return options.filter((option) => OVERSEAS_CAR_TOW_TYPES.includes(option.key));
    }
    return options.filter((option) => OVERSEAS_TOW_TYPES.includes(option.key));
  }
  let result = options.filter((option) => option.applicableProductCodes?.includes(productCode) &&
    option.applicableEventTypes?.includes(eventType));
  if ((!isNoneNullOrUndefined(vehicleType) || vehicleType === VEHICLE_TYPES.NONE) && vehicleTypes) {
    const vehicle = vehicleTypes.find((vehicleTypeItem) => vehicleTypeItem.key === vehicleType);
    if (vehicle) {
      result = result.filter(
        (resultItem) => {
          const tentOnTouringEvent =
            resultItem.key === TOW_TYPES.TENT && eventType === PLACE_EVENT_TYPES.TOURING.id;
          return vehicle.applicableTowTypes.includes(resultItem.key) && !(
            // exclude tent for car/van if touring event
            (tentOnTouringEvent && vehicleType === VEHICLE_TYPES.CAR) ||
            (tentOnTouringEvent && vehicleType === VEHICLE_TYPES.VAN)
          );
        },
      );
    }
  }
  if (!vehicleTypes) {
    result = result.filter((resultItem) => resultItem.key !== VEHICLE_TYPES.NO_CAR &&
      resultItem.key !== VEHICLE_TYPES.NONE);
  }
  return result;
};

export const validateSelectedType = (outfit, vehicleTypes, towTypes) => {
  let updatedOutfit = { ...outfit };

  // Ignore type and length if predefined type is invalid
  const isVehicleTypeValid = vehicleTypes.some(
    (vehicleTypeOption) => vehicleTypeOption.key === outfit.vehicleType,
  );
  const isTowTypeValid = towTypes.some(
    (vehicleTypeOption) => vehicleTypeOption.key === outfit.towType,
  );

  if (!isVehicleTypeValid) {
    updatedOutfit = {
      ...updatedOutfit,
      vehicleType: '',
      vehicleLength: '',
      vehicleHeight: '',
    };
  }
  if (!isTowTypeValid) {
    updatedOutfit = {
      ...updatedOutfit,
      towType: '',
      towLength: '',
      towHeight: '',
    };
  }

  return updatedOutfit;
};

export default filterOutfitOptions;
