import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import Router, { withRouter } from 'next/router';
import { graphql, compose, withApollo } from 'react-apollo';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import {
  Bar, BarLabel, Exit, ExitLabel,
} from './AmendBar.style';
import Icon from '../ui/Icon';
import LoadingSpinner from '../ui/Loading/LoadingSpinner';
import { dictionaryItem } from '../../hocs/withDictionary';
import svgArrowLeft from '../../static/images/icons/ArrowLeft.svg';
import routes from '../../constants/routes';
import GET_POPUP from '../PopUp/graphql/getPopUp';
import RESET_SESSION from '../Quote/graphql/resetSession';
import IbePropTypes from '../../IbePropTypes';
import NoticeModal from '../ui/Modal/NoticeModal/NoticeModal';
import {
  AMEND_MODALS, getModalProps, handleToggleModal,
} from '../../lib/helpers/amend';

import { DATE_FORMAT_DEFAULT } from '../../config/locale';

const AmendBar = ({
  theme,
  id,
  popups,
  client,
  onClick,
  isCheckout,
  router,
  booking,
  quote,
  resetSession,
}) => {
  const [loading, setLoading] = useState(false);

  const getChangedComponents = () => {
    if (!quote || !booking) return null;
    if (!quote?.bookingReference) return null;
    const { campsiteBookings: qCampsiteBookings } = quote;
    const { campsiteBookings: bCampsiteBookings } = booking;
    if (!qCampsiteBookings || !bCampsiteBookings) return [];
    if (qCampsiteBookings?.length <= 0 || bCampsiteBookings?.length <= 0) return [];
    const changedComponents = qCampsiteBookings.map((quoteCampsite, index) => {
      const hasChanged = JSON.stringify(quoteCampsite) !== JSON.stringify(bCampsiteBookings[index]);
      return hasChanged ? { ...quoteCampsite } : null;
    }).filter((componentId) => !!componentId);
    return changedComponents;
  };

  const handleClick = async () => {
    setLoading(true);

    window.amendIntent = 'cancel';
    handleToggleModal(client, 'noticePopUp');

    const pathname = router.asPath.split('?')[0];
    const isOnSites = pathname === routes.sites;

    if (isOnSites) {
      resetSession().finally(() => {
        Router.push(`${routes.myBookingsUpcoming}/${quote?.bookingReference}`);
      });
      return;
    }

    // Find difference between quote and booking campsiteBookings
    // pick whichever has changed, if > 1 pick first
    const changedComponents = getChangedComponents();

    // Guard for no changed components
    const component = changedComponents?.length > 0
      ? changedComponents[0]
      : quote.campsiteBookings[0];

    const selectedPitch = component.pitches[0];

    const queryParams = {
      bookingId: id,
      campsiteId: component.campsite?.id,
      siteCode: component.campsite?.siteCode,
      end: format(selectedPitch.bookingDates.toDate, DATE_FORMAT_DEFAULT),
      start: format(selectedPitch.bookingDates.fromDate, DATE_FORMAT_DEFAULT),
      isOverseas: booking.isOverseasBooking,
      eventType: selectedPitch.eventType,
    };

    setLoading(false);
    Router.push({
      pathname: routes.sites,
      query: queryParams,
    });
  };

  const modalProps = loading ? {
    titleText: 'Loading',
  } : getModalProps(AMEND_MODALS.AMEND_CANCEL);

  const exitLabelText = isCheckout ? dictionaryItem('AmendBar', 'Exit', 'Checkout') : dictionaryItem('AmendBar', 'Exit');

  const handleExitClick = () => {
    if (isCheckout) {
      handleClick();
    } else {
      handleToggleModal(
        client, 'noticePopUp', AMEND_MODALS.AMEND_CANCEL,
      );
    }
  };

  return (
    <Bar>
      <Exit onClick={handleExitClick}>
        <Icon
          icon={svgArrowLeft}
          style={{ marginRight: '1rem' }}
          color={theme.COLOR_WHITE}
        />
        <ExitLabel dictionary={exitLabelText} />
        {loading && <LoadingSpinner size="1rem" />}
      </Exit>
      <BarLabel>Amending Booking #{id}</BarLabel>
      {(popups?.noticePopUp?.type === AMEND_MODALS.AMEND_CANCEL) &&
        <NoticeModal
          open={popups?.noticePopUp?.open}
          {...modalProps}
          onPrimaryAction={() => handleClick()}
          onSecondaryAction={() => handleToggleModal(client, 'noticePopUp')}
          onClickOutside={() => handleToggleModal(client, 'noticePopUp')}
          isLoading={loading}
        />
      }
    </Bar>
  );
};

AmendBar.propTypes = {
  theme: PropTypes.shape({
    COLOR_WHITE: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  popups: PropTypes.shape(IbePropTypes.popups),
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  onClick: PropTypes.func,
  isCheckout: PropTypes.bool,
  router: PropTypes.shape(IbePropTypes.router).isRequired,
  booking: PropTypes.shape(IbePropTypes.booking),
  quote: PropTypes.shape(IbePropTypes.quote),
  resetSession: PropTypes.func,
};

AmendBar.defaultProps = {
  popups: {
    noticePopUp: {
      open: false,
      type: '',
    },
  },
  onClick: () => { },
  isCheckout: false,
  booking: {},
  quote: {},
  resetSession: () => {},
};

export default compose(
  withRouter,
  withApollo,
  withTheme,
  graphql(GET_POPUP, {
    name: 'popups',
  }),
  graphql(RESET_SESSION, {
    props: ({ mutate }) => ({
      resetSession: () => mutate({
        refetchQueries: ['Quote'],
      }),
    }),
  }),
)(AmendBar);
