import { UNIT } from './constants';

// eslint-disable-next-line no-mixed-operators
export const roundLength = (value = null) => Math.ceil(value / UNIT * 2) / 2 * UNIT;

/**
 * Formats mm as pretty metre values
 * @param {number} n The amount
 */
export const formatMMtoMOutfitDimensions = (n, showUnit, prefix = '') => {
  if (!n) return null;

  return `${prefix}${prefix ? ' ' : ''}${(n / UNIT)}${showUnit ? 'm' : ''}`;
};
